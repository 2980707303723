import * as React from "react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import HttpError from "@helper/httpError";

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            retry: false,
            throwOnError: (error) => {
                if (error instanceof HttpError) {
                    if (error.status === 401) {
                        return true;
                    }
                }
                return false;
            }
        }
    },
});

export default function ReactQueryClientProvider({ children }: { children: React.ReactNode }) {
    return <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>;
}